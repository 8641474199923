<template>
  <div class="home">
    <!-- banner -->
    <div class="banner">
      <img src="../assets/image/home page_banner.png" alt="" />
    </div>
    <!-- 产品与服务 -->
    <div class="product">
      <!-- 字体 -->
      <div class="productTop">
        <img src="../assets/image/title_cpfw.png" alt="" />
      </div>
      <!-- 盒子 -->
      <div
        class="productTheme"
        :class="'productTheme' + i"
        v-for="(v, i) in appArr"
        :key="i"
      >
        <ul>
          <li v-for="(singleObj, i) in v" :key="i" @click="liCk(singleObj)">
            <img :src="singleObj.icon" alt="" />
            <span>{{ singleObj.title }}</span>
          </li>
        </ul>
      </div>
    </div>
    <!-- 关于我们 -->
    <div class="aboutUs">
      <div class="aboutUsTop">
        <img src="../assets/image/title_gywm.png" alt="" />
      </div>
      <div class="aboutUsCt">
        <div class="aboutUsCtLt">
          <ul>
            <li v-for="(v, i) in aboutUsTexts" :key="i">
              {{ v }} <br /><br />
            </li>
          </ul>
        </div>
        <div class="kong"></div>
        <div class="aboutUsCtRg">
          <div class="aboutUsCtRgTop">
            <img :src="aboutUs[0]" alt="" />
            <img :src="aboutUs[1]" alt="" />
          </div>
          <div class="aboutUsCtRgBottom">
            <img :src="aboutUs[2]" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { appArrJS } from "../utils/appArrJs";
export default {
  name: "HomeView",
  data() {
    return {
      appArr: appArrJS(),
      aboutUs: [
        require("../assets/image/About-Us/about_img1.png"),
        require("../assets/image/About-Us/about_img2.png"),
        require("../assets/image/About-Us/about_img3.png"),
      ],

      aboutUsTexts: [
        "北京社趣网络科技有限公司长期致力于为政府部门和企业集团提供咨询及信息化管理解决方案，公司成立于2015年08月27日正式上线运营。",
        "随着社会的发展，我们的生活节奏越来越快，高耸大楼不断建起，记忆中左邻右舍的人情味越来越淡，令邻里间产生了许多的陌生感，北京社趣网络科技有限公司围绕“在社区 做邻里”作为新的品牌宗旨，将目标用户定位在社区服务中，致力于深耕社区服务，打造服务于社区，走进邻里生活的线上交流平台。网站所提供的应用增加了社区居民生活服务内容，社区居民可在线上邻里社交，互动交流。 ",
        "这里有您理想中的社区模样，快来加入我们吧，让我们一起留住社区温暖、记录身边美好、赞美社区好人，推动修复和涵养共治共享的社区“生态系统”，从而让社区生活更美好。",
      ],
    };
  },
  methods: {
    liCk({ id }) {
      this.$router.push({ name: "about", query: { id } });
    },
  },
};
</script>
<style scoped>
.banner img {
  min-width: 820px;
}

.aboutUsCt {
  margin-top: 60px;
}

.aboutUsCtLt {
  width: 608px;
  height: 291px;
  font-size: 16px;
  font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
  font-weight: normal;
  color: #ffffff;
  line-height: 24px;
}
.kong {
  width: 120px;
}
.aboutUsCt {
  display: flex;
  justify-content: space-evenly;
}

.aboutUsCtLt {
  width: 608px;
  height: 291px;
}

.aboutUsCtRg {
  display: flex;
  flex-direction: column;
}

.aboutUsCtRgBottom {
  position: relative;
}

.aboutUsCtRg div {
  height: 177px;
}
.aboutUs {
  background-color: rebeccapurple;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 687px;
  background-image: url("../assets/image/home page_2.png");
  background-repeat: round;
  background-size: 100%;
  background-position: left right;
}

.aboutUsTop {
  margin-top: 80px;
  text-align: center;
}

.productTheme ul li {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.productTheme ul li img {
  width: 80px;
  height: 80px;
}

.productTheme ul li span {
  display: inline-block;
  line-height: 30px;
  margin-top: 18px;
}
.productTheme {
  background-color: #ffffff;
  width: 100%;
  margin: 10px 0;
  border-radius: 10px;
  box-shadow: 0px 0px 60px 0px rgba(1, 82, 217, 0.08);
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.product {
  margin: 0 60px;
}

.productTheme ul li {
  width: 16%;
  color: #333333;
  font-size: 22px;
  font-weight: 400;
  line-height: 30px;
}

.productTheme ul {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 60px;
  text-align: center;
  justify-content: space-around;
  height: 178px;
}
.banner {
  width: 100%;
}

.banner img {
  width: 100%;
}

.product {
  /* height: 657px; */
  background-color: #fbfbfb;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: center;
}

.productTop {
  height: 154px;
}

.productTop {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.productTitle {
  font-size: 26px;
  font-weight: 800;
}
</style>
export function appArrJS() {

    var appArr = [
        [{
                id: 1,
                title: "花伴儿",
                icon: require("../assets/image/Home-icon/icon82pt_hb.png"),
            },
            {
                id: 2,
                title: "伴儿",
                icon: require("../assets/image/Home-icon/icon82pt_b.png"),
            },
            {
                id: 3,
                title: "奥缘",
                icon: require("../assets/image/Home-icon/icon82pt_ay.png"),
            },
            {
                id: 4,
                title: "贞心365",
                icon: require("../assets/image/Home-icon/icon82pt_zx(1).png"),
            },
            {
                id: 5,
                title: "巷闻",
                icon: require("../assets/image/Home-icon/icon82pt_ds1.png"),
            },
            {
                id: 6,
                title: "和合动力云空间",
                icon: require("../assets/image/Home-icon/icon82pt_nj.png"),
            },
        ],
        [{
                id: 7,
                title: "钟鼓人家",
                icon: require("../assets/image/Home-icon/icon82pt_zx.png"),
            },
            {
                id: 8,
                title: "玉见",
                icon: require("../assets/image/Home-icon/icon82pt_yj.png"),
            },
            {
                id: 9,
                title: "筑梦三里",
                icon: require("../assets/image/Home-icon/icon82pt_sl.png"),
            },
            {
                id: 10,
                title: "文明潞城",
                icon: require("../assets/image/Home-icon/icon82pt_lc.png"),
            },
            {
                id: 11,
                title: "有邻",
                icon: require("../assets/image/Home-icon/icon82pt_qh.png"),
            },
            {
                id: 12,
                title: "和谐北苑",
                icon: require("../assets/image/Home-icon/icon82pt_by.png"),
            },
        ],

    ];
    return appArr;
}
<template>
  <div>
    <ul>
      <li @click="liViewcK(v.id)" v-for="(v, i) in lis" :key="i">
        {{ v.title }}
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  data() {
    return {
      lis: [
        {
          title: "花伴儿",
          path: "/",
          id: 1,
        },
        {
          title: "伴儿",
          path: "/",
          id: 2,
        },
        {
          title: "奥缘",
          path: "/",
          id: 3,
        },
        {
          title: "贞心365",
          path: "/",
          id: 4,
        },
        {
          title: "巷闻",
          path: "/",
          id: 5,
        },
        {
          title: "和合动力云空间",
          path: "/",
          id: 6,
        },
        {
          title: "钟鼓人家",
          path: "/",
          id: 7,
        },
        {
          title: "玉见",
          path: "/",
          id: 8,
        },
        {
          title: "筑梦三里",
          path: "/",
          id: 9,
        },
        {
          title: "文明潞城",
          path: "/",
          id: 10,
        },
        {
          title: "有邻",
          path: "/",
          id: 11,
        },
        {
          title: "和谐北苑",
          path: "/",
          id: 12,
        },
        {
          title: "城南相伴",
          path: "/",
          id: 13,
        },
		  {
		  title: "追梦永外",
		  path: "/",
		  id: 14,
		  },
      {
          title: "三间房新家园",
          path: "/",
          id: 15,
        },
      ],
    };
  },
  methods: {
    liViewcK(id) {
      this.$router.push({ name: "about", query: { id } });
    },
  },
};
</script>

<style scoped>
ul {
  cursor: inherit;
}
ul li {
  padding: 0;
  margin: 0;
  cursor: inherit;
}

ul li a {
  font-size: inherit;
  line-height: inherit;
  height: inherit;
}
ul li a:hover {
  border: inherit;
}
</style>
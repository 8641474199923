<template>
  <div id="app">
    <!-- App -->
    <nav class="appNav">
      <router-link class="nameOfCompany" to="/we"
        ><img src="./assets/image/titile.png" alt=""
      /></router-link>
      <div class="appNavRight">
        <router-link class="homeRouter" to="/"><span>首页</span></router-link>
        <div
          class="appNavRightProCls"
          @mouseover="isliShow = true"
          @mouseout="isliShow = false"
        >
          <router-link to="/about"
            ><span class="ProCls">公司产品 </span></router-link
          >
          <Lipro class="liPro" v-show="isliShow" />
        </div>
        <router-link class="aboutWe" to="/we">关于我们</router-link>
      </div>
    </nav>
    <router-view />
    <!-- 底部-->
    <div class="desc">
      <span>公司名称：北京社趣网络科技有限公司</span>
      <span
        >版权所有 © 2021 北京社趣网络科技有限公司 保留一切权利
        京ICP备17041543号-1</span
      >
    </div>
  </div>
</template>

<script>
import LiPro from "./components/proLi";
export default {
  components: {
    Lipro: LiPro,
  },
  data() {
    return {
      isliShow: false,
    };
  },
};
</script>

<style>
body {
  margin: 0;
  padding: 0;
  min-width: 1200px;
  cursor: pointer;
}
a,
a:hover,
a:active,
a:visited,
a:link,
a:focus {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
  outline: none;
  background: none;
  text-decoration: none;
}
.appNavRightProCls {
  position: relative;
}
.appNavRightProCls :hover .liPro {
  display: block;
  cursor: inherit;
}
.desc {
  width: 660px;
  height: 17px;
  font-size: 12px;
  font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
  font-weight: normal;
  color: #646a73;
  line-height: 17px;
  text-align: center;
}

.desc {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 18px 0 15px 0;
}
.appNavRightProCls a {
  height: 71px;
  display: block;
}
.appNavRight a:hover {
  line-height: 71px;
  border-bottom: 2px solid #0152d9;
}
.appNavRight a:active {
  line-height: 71px;
  border-bottom: 2px solid #0152d9;
}

.appNavRight a {
  line-height: 71px;
  border-bottom: 2px solid transparent;
}
nav.appNav {
  display: flex;
  margin: 0 120px 0 100px;
  height: 72px;
  justify-content: space-between;
  align-items: center;
}

div#app {
  /* margin: 0 120px 0 100px; */
  background-color: #fbfbfb;
  /* height: 2000px; */
}

.appNavRight {
  display: flex;
  line-height: 31px;
  width: 280px;
  justify-content: space-between;
}
nav.appNav a {
  font-size: 16px;
  font-family: AlibabaPuHuiTi-Medium, AlibabaPuHuiTi;
  font-weight: 500;
  color: #333333;
}
.homeRouter {
  min-width: 32px;
  min-height: 72px;
}
.aboutWe {
  min-width: 64px;
  min-height: 72px;
}

nav.appNav a:active {
  color: #0152d9;
}
.ProCls {
  min-width: 140px;
  min-height: 71px;
  text-align: center;
  display: inline-block;
}
div#app {
  position: relative;
}

.liPro {
  position: absolute;
  left: 0;
  top: 73px;
  z-index: 9;
}
span.ProCls {
  position: relative;
  top: 0;
}
.liPro {
  width: 140px;
  background-color: #80a8ec;
  line-height: 458px;
  font-size: 16px;
  font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
  font-weight: normal;
  color: #ffffff;
}

.liPro ul li {
  line-height: 36px;
}

.liPro ul li:hover {
  background-color: #03205488;
}

a {
  text-decoration: none;
}

.appNav img {
  width: 375px;
  height: 31px;
}
ul {
  margin: 0;
  padding: 0;
  list-style: none;
  text-align: center;
}
</style>
